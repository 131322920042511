.multisend-container {
  position: relative;
  z-index: 2;
  width: 960px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 980px) {
  .multisend-container {
    width: 96%;
  }
}
.header .multisend-container,
.form_header,
.form-inline,
.transactions-title {
  display: flex;
  justify-content: space-between;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
html,
body {
  color: #9cd8ff;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
}
html,
body,
p,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
html {
  height: 100%;
}
body {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #011d2f;
}
body:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-image: url(../images/light@2x.png);
  background-size: 100% 100%;
}
p {
  color: #fff;
  line-height: 22px;
  font-size: 14px;
}
a {
  color: #10b3ff;
}
a:hover {
  text-decoration: none;
}
strong {
  font-weight: bold;
}
.title {
  margin-bottom: 30px;
  color: #fff;
  line-height: 46px;
  font-size: 32px;
  font-weight: 300;
  box-sizing: content-box;
}
@media (max-width: 414px) {
  .title {
    font-size: 26px;
  }
}
.description {
  margin-bottom: 20px;
  color: #9cd8ff;
}
.multisend-container_bg {
  padding-bottom: 50px;
}
.multisend-container_opacity:before {
  opacity: 0.2;
}
.content {
  position: relative;
  z-index: 1;
  width: 100%;
}
.container_opacity .content {
  width: 100%;
}
@media (max-width: 980px) {
  .content {
    width: 100%;
  }
}
.break-all {
  word-break: break-all;
}
.header {
  margin-bottom: 40px;
  padding: 20px 0;
  border-bottom: 1px solid rgba(149, 213, 255, 0.1);
}
@media (max-width: 414px) {
  .header {
    margin-bottom: 30px;
  }
}
.header .multisend-container {
  align-items: center;
/*  justify-content: flex-end;*/
}
.header .form {
  flex-wrap: inherit;
}
.header .multisend-label {
  display: block;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
  white-space: nowrap;
}
.form_header .header .multisend-label {
  margin-right: 15px;
  margin-bottom: 0;
}
.header-logo {
/*  display: none;*/
  font-size: 22px;
  text-decoration: none;
}
@media (max-width: 980px) {
  .header-logo {
    width: 36px;
    background-size: 200px 100%;
  }
}
.input,
.select,
.multisend-textarea,
.multisend-textarea.form-control,
.multisend-button {
  outline: none;
  border-radius: 4px;
  line-height: 40px;
}
.header .multisend-label,
.input,
.select,
.multisend-textarea,
.multisend-textarea.form-control,
.multisend-label,
.multisend-button {
  color: #fff;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}
#addresses-with-balances {
  line-height: 20px;
}
#addresses-with-balances::-webkit-input-placeholder {
  color: #8e9ea9;
  opacity: 0.7;
}
#addresses-with-balances:-moz-placeholder {
  color: #8e9ea9;
  opacity: 0.7;
}
#addresses-with-balances::-moz-placeholder {
  color: #8e9ea9;
  opacity: 0.7;
}
#addresses-with-balances:-ms-input-placeholder {
  color: #8e9ea9;
  opacity: 0.7;
}
.form span.error {
  margin-left: 10px;
  color: #fd7491;
}
.form_header {
  align-items: center;
}
.form-group {
  width: 100%;
}
.form-inline {
  align-items: flex-start;
}
.form-inline-i_token-address {
  width: 100%;
  margin-bottom: 20px;
}
.form-inline-i_token-address-values {
  width: 100%;
}
.form-inline-i_gas-price {
  width: 100%;
  margin-bottom: 20px;
}
.form-inline-i_gas-sharing {
  width: 100%;
}
.form-inline-i_token-decimals {
  width: 15%;
}
.form-inline-i_balance {
  width: 40%;
}
.input,
.select,
.multisend-textarea {
  width: 100%;
  margin-bottom: 20px;
  transition: 0.3s box-shadow;
  box-sizing: border-box;
  border: 1px solid #95d5ff;
  background-color: rgba(255, 255, 255, 0.1);
}
.input:focus,
.select:focus,
.multisend-textarea:focus {
  box-shadow: 0px 10px 20px 0 rgba(16, 179, 255, 0.3);
}
.input,
.select {
  height: 40px;
}
.input {
  padding: 0 20px;
}
.input[type="number"] {
  padding-right: 0px;
}
.select {
  padding: 0 38px 0 20px;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAMAAADHqI+lAAAACVBMVEWT1P+T1P+T1P9um/dXAAAAAnRSTlMAgJsrThgAAAAlSURBVHgBVcixDQAgAMOwlv+PhqWWyBan56she+KJf+KJJ57sLy2wAIFnBVRPAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 8px 4px;
  background-position: center right 20px;
}
.form_header .select {
  max-width: 160px;
  margin-bottom: 0;
}
.multisend-textarea,
.multisend-textarea.form-control {
  width: 100%;
  margin-bottom: 20px;
  transition: 0.3s box-shadow;
  box-sizing: border-box;
  border: 1px solid #95d5ff;
  background-color: rgba(255, 255, 255, 0.1);
  resize: none;
  height: 186px;
  padding: 10px 10px;
  font-family: monospace;
}
.multisend-textarea:focus,
.multisend-textarea.form-control:focus {
  box-shadow: 0px 10px 20px 0 rgba(16, 179, 255, 0.3);
}
.multisend-label {
  display: block;
  margin-bottom: 20px;
}
.form_header .multisend-label {
  margin-right: 15px;
  margin-bottom: 0;
}
.multisend-button {
  cursor: pointer;
  display: inline-block;
  transition: 0.3s box-shadow;
  border: 0;
  padding: 0 20px;
  background-color: #10b3ff;
  background-repeat: no-repeat;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}
.multisend-button:disabled {
  -moz-box-shadow: inset 0 0 10px rgba(11, 72, 101, 0.3);
  -webkit-box-shadow: inset 0 0 10px rgba(11, 72, 101, 0.3);
  box-shadow: inset 0 0 10px rgba(11, 72, 101, 0.3);
  opacity: 0.88;
}
.multisend-button:disabled:hover {
  box-shadow: inset 0 0 10px rgba(11, 72, 101, 0.3);
}
.multisend-button:hover {
  box-shadow: 0px 10px 20px 0 rgba(16, 179, 255, 0.3);
}
.multisend-button_next {
  float: right;
  padding-right: 45px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAMFBMVEX///////////////////////////////////////////////////////////////9Or7hAAAAAD3RSTlMAPT5BSUpRUt3f5ufp6vUxUI2NAAAAS0lEQVR4AWPABGy8HIwMuAEvPz8fM25pDn688kx8+OWZ6SXPwsWPE/CxMwBlcQMe/NK8DKzc+AzH7zT6yiKiBH+Ekpsc2Hg54YkJAFFVCxvQpdH1AAAAAElFTkSuQmCC);
  background-size: 15px 10px;
  background-position: center right 20px;
}
.multisend-button_prev {
  padding-left: 45px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAM1BMVEVsb3P///////////////////////////////////////////////////////////////+xgv+bAAAAAXRSTlMAQObYZgAAADxJREFUKM9jYIABTj4GPIBfQIAPr6wAJ15Z/kEpiwNwMzDwCgjgk+fDL43PcB7CThsc8pxkJwcGBi5ssgD2ZQtsy4BYVQAAAABJRU5ErkJggg==);
  background-size: 15px 10px;
  background-position: center left 20px;
}
.multisend-button_add {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 50%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAFVBMVEX///////////////////////////9nSIHRAAAABnRSTlMASUrk5udXTd49AAAAPUlEQVR4Ae3SMRKAAAwCQRIv/P/JWqsz2JutrwPdFZQSbJT4stHfosIBJRzxJRr1ODh6Z9noBfYo6Xn+5wToPQYflYXefQAAAABJRU5ErkJggg==);
  background-size: 18px 18px;
  background-position: center center;
}
#token-address {
  color: #fff;
}
.table {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.table-tr {
  display: table-row;
}
.table-tr:not(:last-child) .table-td {
  border-bottom: 1px solid rgba(152, 215, 255, 0.3);
}
.table-td {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0;
}
.table-td:last-child {
  text-align: right;
}
.table-td:first-child {
  text-align: left;
  white-space: nowrap;
}
.table-td:not(:last-child) {
  padding-right: 20px;
}
.table-tr_title .table-td {
  color: #9cd8ff;
  font-weight: bold;
}
.table-td_hash {
  color: #9cd8ff;
}
.table-td_check-hash {
  padding-left: 32px;
  background-repeat: no-repeat;
  background-position: left center;
}
.table-td_check-hash_wait {
  color: #9cd8ff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAw1BMVEWK0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+K0f+nW6LJAAAAQHRSTlMAAQIDBQYHDA0bHB8gISQmJyg+P0BGR0lKU1RZYmNrbHV2iYqLo6Spqqusra7Cw8TFysvM29zk5ufo6/Lz+Pn8DzgVHgAAATNJREFUeNplk+1agkAQhQfRykQTrJTEUjSCvtTQECPk/q+qPQ7LsnR+7DOzL898LiRlOP46zfN0vXQM0tXydkWlndeqs/620LTtK3Z7LBo6OpLd5fDzl6nVubSnIXv3zIY/8N4HVGrwBj8bwjaR77SgmhYn5DWF5eE7ZoribkZkoIeI78ZJMmYrQkcGjZD9mq+SokjY6qKqEfniDIiFYMR6FqZPn+J8+A9dYX5QKs5eBaFz3p4wUkLwizrkvB2UcoZXJUwYHiqIsDclnBzAvicyrFYQpBW0lK0oqVbkEHR1fzEEHl/YhCHG1yKaoYi5zubl4Mn8wsqe6uwRK4tNmHaG717VsiP4mV1uih9G4FrttuUG7GGGTLOioUwyRI51Ftv6o94rtNcfNbCz2uB32KycCv0BfU5XCrQEyKwAAAAASUVORK5CYII=);
  background-size: 14px 14px;
}
.table-td_check-hash_done {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAMAAACpgK3LAAAAIVBMVEUevocevocevocevocevocevocevocevocevocevocevofSHwXKAAAACnRSTlMAPT5BUVLd6er1QK/QCAAAAFBJREFUeNq10jkOwDAIRFFvwZj7HzjlVzSYLlQMT4iGdqtu/txsnohdWFhhZxQ2f7Pl1q97TivWjKD3BlGMgdhXMVUsUSxRTBTLFNNarm/xApvEBXS2sERVAAAAAElFTkSuQmCC);
  background-size: 14px 10px;
}
.table-td_check-hash_error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAw1BMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZxT1WAAAAQHRSTlMAAQIDBQYHDA0bHB8gISQmJyg+P0BGR0lOUFNUWWJjbHV2iYqLo6SpqqusrcLDxMXKy8zP29zj5+jr8vP4+fr8rwaczAAAASBJREFUKM9l0tlWwkAQRdFLABUZJEGNMqmACA5MBg3GhvP/X+VDE2I6963W7pXqrpR0TCkYLWJj4sU4KCkfbxBxSjTw/ltzRS6rZmbXO5zsgtRuDIB56Teq535/Zqtba+0fgM9Werb1BpC0Jals++17WZunA7AqSxoc2+QUYCiVImC9d3QORCV1AHPZdbRmgI5GwFRy9RkY6QPoqaBd4F0xUFdB60AsA5ypoFXAyAAXKqjFGLhSQe1n0ws5ai80tk9x1T7FDsHRu9ov0LHjmymv3zMg8qQhwKOjx8GrvAYOD5nefwGwKUuSnwC8Zj97DpD4tgrtYky7jUql0Z3aKkzPhom7YEmYtfE3edv4+aXeZrTNL7UkL5gsY2Pi5SQ40R9LbmAB1Sn/1gAAAABJRU5ErkJggg==);
  background-size: 14px 14px;
}
.table-remove {
  cursor: pointer;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAKlBMVEUAsf8Asf8Asf8Asf8Asf8Asf8Asf8Asf8Asf8Asf8Asf8Asf8Asf8Asf9ztzDMAAAADXRSTlMATE1OUFFTVOPm5+j60HDtDwAAAFVJREFUGNNt0NEOwDAEBVDrbC3j/3937TKiwdPNSQig0bjACwchkKp0k1tUn0VuSyYd4vaJnBZ6CB6DmEX5bROzKAXlxjw+L5FXLQ5KZ3P1nMb7Cxlfp8QIeHWv2ywAAAAASUVORK5CYII=);
  background-position: center center;
  background-size: 8px 8px;
  background-repeat: no-repeat;
}
.send-info {
  word-wrap: break-word;
  overflow: hidden;
}
.send-info-side {
  float: left;
  width: 50%;
}
.send-info-i {
  margin-bottom: 20px;
}
.send-info-amount {
  margin-top: 10px;
  color: #9cd8ff;
  font-size: 16px;
}
.transactions-title {
  margin-bottom: 20px;
}
@media (max-width: 414px) {
  .transactions-title {
    margin-bottom: 0;
  }
}
.socials {
  font-size: 0;
}
@media (max-width: 414px) {
  .socials {
    display: none;
  }
}
.socials-i {
  transition: 0.3s background-color;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}
.socials-i:not(:first-child) {
  margin-left: 10px;
}
.socials-i:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.socials-i:before {
  transform: translate(-50%, -50%);
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../images/socials.png);
}
@media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6 / 2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
  .socials-i:before {
    background-image: url("../images/socials@2x.png");
    background-size: 16px 69px;
  }
}
.socials-i_github:before {
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
.socials-i_oracles:before {
  width: 16px;
  height: 14px;
  background-position: 0 -16px;
}
.socials-i_reddit:before {
  width: 15px;
  height: 13px;
  background-position: 0 -30px;
}
.socials-i_telegram:before {
  width: 16px;
  height: 14px;
  background-position: 0 -43px;
}
.socials-i_twitter:before {
  width: 15px;
  height: 12px;
  background-position: 0 -57px;
}
